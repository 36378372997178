import React, { useEffect, useState } from "react";
import theme from "../theme";
import { useActive } from "../context/activeContext";
import { useAuth } from "../context/authContext";
import api from "../service";
import Switch from "@mui/material/Switch";

import MuiAlert from "@mui/material/Alert";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const getHours = (interval) => {
  // Define the desired interval in minutes

  // Convert the interval to milliseconds
  var intervalInMilliseconds = interval * 60 * 1000;

  // Define the initial date to 00:00
  var initialDate = new Date();
  initialDate.setHours(0, 0, 0, 0);

  // Define the final date to 23:59
  var finalDate = new Date();
  finalDate.setHours(23, 59, 0, 0);

  // Initialize the variable to store the times
  var times = [];

  // Loop to generate the times within the specified interval
  var currentTime = initialDate;
  while (currentTime <= finalDate) {
    var formattedTime =
      currentTime.getHours().toString().padStart(2, "0") +
      ":" +
      currentTime.getMinutes().toString().padStart(2, "0");
    times.push(formattedTime);
    currentTime.setTime(currentTime.getTime() + intervalInMilliseconds);
  }

  // return result
  return times;
};

let FormSchedule = ({
  show,
  hoursBath,
  hoursVet,
  hoursHotel,
  setHoursBath,
  setHoursVet,
  setHoursHotel,
  daysWeekBath,
  daysWeekVet,
  daysWeekHotel,
  setDaysWeekBath,
  setDaysWeekVet,
  setDaysWeekHotel,
}) => {
  const { active, updateActive, services, setServices } = useActive();
  const { user, setUser } = useAuth();
  const [open, setOpen] = useState(false);
  const [category, setCategory] = useState([]);
  const [selectCategory, setSelectCategory] = useState({});
  const [selectWeek, setSelectWeek] = useState({});
  const [profile, setProfile] = useState({});
  const [schedule, setSchedule] = useState({});
  const [defaultSchedule, setDefaultSchedule] = useState({});
  const [interval, setInterval] = useState(1);

  const week = [
    {
      code: 1,
      name: "Segunda",
    },
    {
      code: 2,
      name: "Terça",
    },
    {
      code: 3,
      name: "Quarta",
    },
    {
      code: 4,
      name: "Quinta",
    },
    {
      code: 5,
      name: "Sexta",
    },
    {
      code: 6,
      name: "Sabádo",
    },
    {
      code: 0,
      name: "Domingo",
    },
  ];

  useEffect(() => {
    (async () => {
      let { data } = await api.provider.getServicesOptions();

      setCategory(data.data);

      data = await api.provider.getUser();
      setProfile(data.data.data.info);
      let scheduleList = {};
      data.data.data.info.schedule.map((s) => {
        scheduleList[s.category] = s;
      });
      console.log("scheduele", scheduleList);
      setDefaultSchedule(scheduleList);
      setSchedule(scheduleList);
    })();
  }, []);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleOnClikDaysBath = (index) => {
    let values = [...daysWeekBath];
    values[index].enable = !values[index].enable;
    setDaysWeekBath(values);
  };

  const handleOnClikDaysVet = (index) => {
    let values = [...daysWeekVet];
    values[index].enable = !values[index].enable;
    setDaysWeekVet(values);
  };

  const handleOnClikDaysHotel = (index) => {
    let values = [...daysWeekHotel];
    values[index].enable = !values[index].enable;
    setDaysWeekHotel(values);
  };

  const handleOnClikHoursBath = (index) => {
    let values = [...hoursBath];
    values[index].enable = !values[index].enable;
    setHoursBath(values);
  };

  const handleOnClikHoursVet = (index) => {
    let values = [...hoursVet];
    values[index].enable = !values[index].enable;
    setHoursVet(values);
  };

  const handleOnClikHoursHotel = (index) => {
    let values = [...hoursHotel];
    values[index].enable = !values[index].enable;
    setHoursHotel(values);
  };

  const pageStyle = {
    category: (item) => {
      return {
        width: "20%",
        margin: "2%",
        fontSize: "18px",
        fontWeight: selectCategory._id === item._id ? "500" : "400",
        color: selectCategory._id === item._id ? "#011627" : "black",
        textDecorationLine:
          selectCategory._id === item._id ? "underline" : "none",
        backgroundColor:
          selectCategory._id === item._id ? "#F1F1F1" : "transparent",
        borderRadius: "16px",
        padding: "1%",
        cursor: "pointer",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexWrap: "no-wrap",
        boxShadow:
          selectCategory._id === item._id
            ? schedule[item._id] && schedule[item._id].disabled === false
              ? "0px 0px 3px 1px  lightgreen"
              : "0px 0px 3px 1px  rgba(0,0,0,.4)"
            : schedule[item._id] && schedule[item._id].disabled === false
            ? "0px 0px 2px 1px  lightgreen"
            : "0px 0px 2px 0px  rgba(0,0,0,.2)",
      };
    },
    week: (item) => {
      return {
        margin: "4%",
        fontSize: "18px",
        fontWeight: "500",
        color: selectWeek?.code === item.code ? "#011627" : "black",
        textDecorationLine:
          selectWeek?.code === item.code ? "underline" : "none",
        backgroundColor:
          selectWeek?.code === item.code ? "#F1F1F1" : "transparent",
        borderRadius: "16px",
        padding: "6%",
        cursor: "pointer",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexWrap: "no-wrap",
        boxShadow:
          selectWeek?.code === item.code
            ? schedule[selectCategory._id] &&
              schedule[selectCategory._id].week &&
              schedule[selectCategory._id].week[item.code] &&
              schedule[selectCategory._id].week[item.code].enabled === true
              ? "0px 0px 3px 1px  lightgreen"
              : "0px 0px 1px 0px  rgba(0,0,0,.4)"
            : schedule[selectCategory._id] &&
              schedule[selectCategory._id].week &&
              schedule[selectCategory._id].week[item.code] &&
              schedule[selectCategory._id].week[item.code].enabled === true
            ? "0px 0px 3px 1px  lightgreen"
            : "0px 0px 1px 0px  rgba(0,0,0,.2)",
      };
    },
    hour: (item) => {
      return {
        minWidth: "8%",
        maxWidth: "8%",
        minHeight: "8%",
        maxHeight: "8%",
        margin: "2%",
        fontSize: "18px",
        borderRadius: "16px",
        padding: "2%",
        cursor: "pointer",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexWrap: "no-wrap",
        fontWeight: "500",
        color:
          schedule[selectCategory._id] &&
          schedule[selectCategory._id].week &&
          schedule[selectCategory._id].week[selectWeek.code] &&
          schedule[selectCategory._id].week[selectWeek.code].off_hours.includes(
            item
          )
            ? "#011627"
            : "black",
        textDecorationLine:
          schedule[selectCategory._id] &&
          schedule[selectCategory._id].week &&
          schedule[selectCategory._id].week[selectWeek.code] &&
          schedule[selectCategory._id].week[selectWeek.code].off_hours.includes(
            item
          )
            ? "line-through"
            : "none",
        backgroundColor:
          schedule[selectCategory._id] &&
          schedule[selectCategory._id].week &&
          schedule[selectCategory._id].week[selectWeek.code] &&
          schedule[selectCategory._id].week[selectWeek.code].off_hours.includes(
            item
          )
            ? "#bfbfbf"
            : "lightgreen",
      };
    },
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        padding: "1%",
        flex: 1,
        flexWrap: "wrap",
      }}
    >
      {/* // Listar todas as categorias */}
      <p style={{ fontSize: 24, fontWeight: "600" }}>Selecione categoria</p>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "row",
          padding: "1%",
          backgroundColor: "transparent",
          border: "1px",
          borderRadius: "8px",
          marginLeft: "1%",
          wrap: "wrap",
          width: "100%",
        }}
      >
        {category.map((item, index) => {
          if (item.code !== 4)
            return (
              <div
                onClick={() => {
                  setSelectCategory(item);
                  console.log("verItem", item);
                }}
                style={pageStyle.category(item)}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    flexDirection: "column",
                    flex: 1,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flex: 1,
                      width: "100%",
                    }}
                  >
                    <img
                      src={item.image}
                      style={{
                        width: "40px",
                        maxWidth: "35%",
                        margin: "1%",
                      }}
                    />
                    <p style={{ marginLeft: "2%", flex: 1 }}>{item.name}</p>
                  </div>
                  {/* Switch */}
                  <div
                    style={{
                      flex: 1,
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Switch
                        // checked={isActive}
                        checked={
                          schedule[item._id] &&
                          schedule[item._id].disabled === false
                        }
                        value={
                          schedule[item._id] &&
                          schedule[item._id].disabled === false
                        }
                        onChange={async () => {
                          let cpSchedule = schedule;
                          if (!schedule[item._id])
                            cpSchedule[item._id] = {
                              category: item._id,
                              disabled: false,
                              interval,
                              week: {},
                              holiday: {
                                enabled: true,
                                off_hours: [],
                              },
                            };
                          else
                            cpSchedule[item._id] = {
                              ...cpSchedule[item._id],
                              disabled: !schedule[item._id].disabled,
                            };

                          setSchedule(cpSchedule);
                          console.log("verSchedule", cpSchedule);
                          await api.provider.categorySchedule(
                            Object.values(cpSchedule)
                          );
                        }}
                        size="medium"
                      />
                      <div
                        style={{
                          fontSize: 14,
                          fontFamily: "Poppins",
                          textAlign: "center",
                        }}
                      >
                        {schedule[item._id]?.disabled === false ? (
                          <p>Ativo</p>
                        ) : (
                          <p>Inativo</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
        })}
      </div>

      <div
        style={{
          display: selectCategory._id ? "flex" : "none",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          flex: 1,
        }}
      >
        <p
          style={{
            fontSize: 24,
            fontWeight: "600",
          }}
        >
          Dias da Semana
        </p>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "1%",
            flex: 1,
          }}
        >
          {week.map((w) => {
            return (
              <div
                style={pageStyle.week(w)}
                onClick={() => {
                  setSelectWeek(w);
                }}
              >
                {w.name}
              </div>
            );
          })}
        </div>
        <div
          style={{
            display: selectCategory._id && selectWeek.name ? "flex" : "none",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
          }}
        >
          <p
            style={{
              fontSize: 24,
              fontWeight: "600",
            }}
          >
            Horário de atendimento
          </p>
          <div
            style={{
              flex: 1,
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                flex: 1,
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  display: selectWeek?.name?.length > 0 ? "flex" : "none",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Switch
                  // checked={isActive}
                  checked={
                    schedule[selectCategory._id] &&
                    schedule[selectCategory._id].week &&
                    schedule[selectCategory._id].week[selectWeek.code] &&
                    schedule[selectCategory._id].week[selectWeek.code]
                      .enabled === true
                      ? true
                      : false
                  }
                  value={
                    schedule[selectCategory._id] &&
                    schedule[selectCategory._id].week &&
                    schedule[selectCategory._id].week[selectWeek.code] &&
                    schedule[selectCategory._id].week[selectWeek.code]
                      .enabled === true
                      ? true
                      : false
                  }
                  onChange={async () => {
                    let cpSchedule = { ...schedule };

                    if (!cpSchedule[selectCategory._id])
                      cpSchedule[selectCategory._id] = {
                        category: selectCategory._id,
                        disabled: false,
                        interval,
                        week: {
                          [selectWeek.code]: {
                            code: selectWeek.code,
                            name: selectWeek.name,
                            enabled: true,
                            off_hours: [],
                          },
                        },
                      };
                    else if (!cpSchedule[selectCategory._id].week)
                      cpSchedule[selectCategory._id] = {
                        ...cpSchedule[selectCategory._id],
                        week: {
                          [selectWeek.code]: {
                            code: selectWeek.code,
                            name: selectWeek.name,
                            enabled: true,
                            off_hours: [],
                          },
                        },
                      };
                    else if (
                      !cpSchedule[selectCategory._id].week[selectWeek.code]
                    )
                      cpSchedule[selectCategory._id] = {
                        ...cpSchedule[selectCategory._id],
                        week: {
                          ...cpSchedule[selectCategory._id].week,
                          [selectWeek.code]: {
                            code: selectWeek.code,
                            name: selectWeek.name,
                            enabled: true,
                            off_hours: [],
                          },
                        },
                      };
                    else
                      cpSchedule[selectCategory._id].week[selectWeek.code] = {
                        ...cpSchedule[selectCategory._id].week[selectWeek.code],
                        enabled:
                          !cpSchedule[selectCategory._id].week[selectWeek.code]
                            .enabled,
                      };

                    setSchedule(cpSchedule);
                    await api.provider.categorySchedule(
                      Object.values(cpSchedule)
                    );
                  }}
                  size="medium"
                />
                <div
                  style={{
                    fontSize: 14,
                    fontFamily: "Poppins",
                    textAlign: "center",
                  }}
                >
                  {schedule[selectCategory._id] &&
                  schedule[selectCategory._id].week &&
                  schedule[selectCategory._id].week[selectWeek.code] &&
                  schedule[selectCategory._id].week[selectWeek.code].enabled ===
                    true ? (
                    <p>Ativo</p>
                  ) : (
                    <p>Inativo</p>
                  )}
                </div>
              </div>
            </div>
            <p
              style={{
                margin: "1%",
                fontSize: 16,
                fontWeight: "400",
                flex: 1,
              }}
            >
              Selecione o horário para desativá-lo
            </p>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  flex: 1,
                }}
              >
                <div
                  style={{
                    width: "20px",
                    height: "20px",
                    borderRadius: "100px",
                    backgroundColor: "#bfbfbf",
                    margin: "20px",
                  }}
                ></div>
                Desativado
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    width: "20px",
                    height: "20px",
                    borderRadius: "100px",
                    backgroundColor: "lightgreen",
                    margin: "20px",
                  }}
                ></div>
                Ativo
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "space-between",
              padding: "1%",
              flex: 1,
              minWidth: "100%",
              minHeight: "100%",
              flexWrap: "wrap",
            }}
          >
            {getHours(60).map((h) => {
              return (
                <div
                  style={pageStyle.hour(h)}
                  onClick={async () => {
                    let cpSchedule = { ...schedule };

                    if (schedule[selectCategory._id]) {
                      if (
                        schedule[selectCategory._id].week &&
                        schedule[selectCategory._id].week[selectWeek.code]
                      ) {
                        if (
                          !schedule[selectCategory._id].week[
                            selectWeek.code
                          ].off_hours.includes(h)
                        ) {
                          cpSchedule[selectCategory._id].week[
                            selectWeek.code
                          ].off_hours.push(h);
                        } else
                          cpSchedule[selectCategory._id].week[
                            selectWeek.code
                          ].off_hours.splice(
                            schedule[selectCategory._id].week[
                              selectWeek.code
                            ].off_hours.indexOf(h),
                            1
                          );
                      } else {
                        if (!cpSchedule[selectCategory._id].week)
                          cpSchedule[selectCategory._id].week = {
                            [selectWeek.code]: {
                              code: selectWeek.code,
                              name: selectWeek.name,
                              enabled: true,
                              off_hours: [h],
                            },
                          };
                        else
                          cpSchedule[selectCategory._id].week[selectWeek.code] =
                            {
                              code: selectWeek.code,
                              name: selectWeek.name,
                              enabled: true,
                              off_hours: [h],
                            };
                      }
                    } else {
                      cpSchedule = {
                        ...schedule,
                        [selectCategory._id]: {
                          category: selectCategory._id,
                          disabled: false,
                          interval,
                          week: {
                            [selectWeek.code]: {
                              code: selectWeek.code,
                              name: selectWeek.name,
                              enabled: true,
                              off_hours: [h],
                            },
                          },
                          holiday: {
                            enabled: true,
                            off_hours: [],
                          },
                        },
                      };
                    }
                    setSchedule(cpSchedule);

                    await api.provider.categorySchedule(
                      Object.values(cpSchedule)
                    );
                  }}
                >
                  {/* colocar checkbox e habilitar/desabilitar */}
                  {/* colocar botao de salvar */}
                  {/* colocar limpa/selecionar */}
                  {/* estilo */}

                  {h}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
export default FormSchedule;
